<template>
  <div class="home">

    <div class="container animate__animated animate__fadeIn">

      <div class="row px-3 py-3">
        <div class="col-12 text-center">
          <h1 class="text-primary" v-on:click="admin()"><i class="fad fa-ticket-alt"></i></h1>
          <h1 class="text-primary mb-0">Sistema de Servicios y Garantía</h1>
          
          <div class="row"><hr class="col-3 mx-auto"></div>
          
          <p class="text-muted">
            <a href="https://x-orsystem.com/servicios-y-garantias" class="text-decoration-none" target="_blank">Consulta aquí las políticas de Servicios y Garantías</a>
          </p>
          <p class="text-muted">Ingresa tu número de Servicio</p>
          
          <div class="row d-flex justify-content-center">
            
            <div class="col-4 px-0 mx-0">
              <b-form-input v-model="support_folio" ref="input_folio" block class="border-secondary border-right-0 rounded-0 mx-0" />
            </div>

            <div class="col-1 px-0 mx-0">
              <b-button v-on:click="search()" block variant="white" class="border-secondary border-left-0 rounded-0 mx-0 text-secondary">
                <i class="fas fa-search px-0"></i>
              </b-button>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  name: 'Home',
  data() {
    return {
      support_folio: '',

      xwt: '',
      customer: '',
      is_validated: '',
    }
  },
  methods: {
    search() {
      this.$router.push('/service/' + this.support_folio)
    },
    admin() {
      if(
        this.group_id == 2 ||
        this.group_id == 3 ||
        this.group_id == 4 || 
        this.group_id == 5
      ) {
        if(this.is_validated) this.$router.push('/admin')
      }
    },
    async validate_customer() {

      var headers = { xwt: this.xwt }

      axios.get(settings.api().endpoint + settings.api().path + '/validate_customer', { headers }).then( (r) => {
        this.group_id = r.data.data.group_id
        this.is_validated = r.data.data.status
      })

    }
  },
  mounted() {
    this.support_folio = ''
    this.$refs.input_folio.focus()
    
    this.is_validated = false

    this.xwt = this.$cookies.get('xwt')
    if(this.xwt !== null) {
      this.validate_customer()
      
      this.$store.commit('set_token', this.xwt)
      this.$store.commit('set_customer', JSON.parse(atob(this.xwt.split('.')[1])) )
    } else {
      this.$store.commit('set_token', settings.api().token)
    }
  },
}
</script>
