const MODE = 0

const APP_PATH = [ '/', '/']

const API_ENDPOINT = [ 'https://soporte.x-orsystem.com', 'http://soporte.xsys.com' ]
const API_PATH = [ '/api/public', '/api' ]
const API_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6IjIzIiwiY3VzdG9tZXJfZ3JvdXBfaWQiOiIxIiwiZmlyc3RuYW1lIjoiRWxvaXIiLCJsYXN0bmFtZSI6IkNvcm9uYSIsImVtYWlsIjoiZWxvaXJjb3JvbmFoQG91dGxvb2suY29tIiwidGVsZXBob25lIjoiMzMzODgyODg4MjEiLCJpcCI6IjE4Ny4yMTIuMjA1LjY5In0.LdZoRhKh3WNLfBwX21Ym8gzOkwNVduixHSsbsD47V8c' 

const COOKIES_DOMAIN = [ 'x-orsystem.com', 'xsys.com' ]
const COOKIES_SECURE = [ true, false ]

const FILES_PATH = [ '/home4/xorsyste/projects/xsys/files', '' ]

export default {

  app() { return { 
    path: APP_PATH[MODE]
  } },

  api() { return { 
    endpoint: API_ENDPOINT[MODE],
    path: API_PATH[MODE],
    token: API_TOKEN
  } },

  cookies() { return {
    domain: COOKIES_DOMAIN[MODE],
    secure: COOKIES_SECURE[MODE]
  } },

  files() { return {
    path: FILES_PATH[MODE]
  } },

}