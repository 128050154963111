import settings from '../settings'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },

  { path: '/admin', component: () => import('@/views/Admin.vue') },
  { path: '/report', component: () => import('@/views/Report.vue') },

  { path: '/service', redirect: '/' },
  { path: '/service/:id', component: () => import('@/views/Service.vue') },

  { path: '/loading', component: () => import('@/views/Loading.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: settings.app().path,
  scrollBehavior() { return { x: 0, y: 0 }; },
  routes
})

export default router
