import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loading: '',
    token: '',
    customer: '',
  },
  mutations: {
    set_loading(state, data) { state.loading = data },
    set_token(state, data) { state.token = data },
    set_customer(state, data) { state.customer = data },
  },
  getters: {
    get_loading(state) { return state.loading },
    get_token(state) { return state.token },
    get_customer(state) { return state.customer },
  }
})

export default store 