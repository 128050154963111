<template>
  <div id="app">

    <app-header application="support" />

    <router-view />
    
  </div>
</template>

<script>
export default {
  components: {
    AppHeader: () => import('@/components/AppHeader.vue')
  }
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
/* Scrollbar */
::-webkit-scrollbar { width: 4px; height: 2px; }
::-webkit-scrollbar-button { width: 0px; height: 0px; }
::-webkit-scrollbar-thumb { background: #212529; border: 0px none #212529; border-radius: 50px; }
::-webkit-scrollbar-thumb:hover { background: #212529; }
::-webkit-scrollbar-thumb:active { background: #000000; }
::-webkit-scrollbar-track { background: #666666; border: 0px none #ffffff; border-radius: 50px; }
::-webkit-scrollbar-track:hover { background: #666666; }
::-webkit-scrollbar-track:active { background: #333333; }
::-webkit-scrollbar-corner { background: transparent; }
</style>